<template lang="pug">
	.header-main
		.header(:class="{'is-opened' : isOpen, 'is-opened-list': isListMonetization || isListNeurobot}")
			a.d-block.cursor-pointer.header__logo(@click="handleClickMainMenu('all')")
			.header__burger-wrap(v-if="!isOpen")
				span.header__burger(@click="open")

			nav.header__nav
				.header__nav-item
					span.header__nav-link.is-monetization.cursor-pointer(@click="handleClickMainMenu('monetization')") {{ $t('landingPage.header.navList[0]') }}
						.header__nav-wrap
							.header__nav-inner
								span(@click.stop="handleClick('monetization', '#monetizationPreview')") {{ $t('landingPage.header.monetizationList[0]') }}
								span(@click.stop="handleClick('monetization', '#authorsMainProjects')") {{ $t('landingPage.header.monetizationList[1]') }}
								span(@click.stop="handleClick('monetization', '#reviewsTitle')") {{ $t('landingPage.header.monetizationList[2]') }}
								span(v-if="islocaleRuSystemAndIp" @click.stop="handleClick('monetization', '#write', 10)") {{ $t('landingPage.header.monetizationList[3]') }}
								span(@click.stop="handleClick('monetization', '#stepsTitle')") {{ $t('landingPage.header.monetizationList[4]') }}
								span(@click.stop="handleClick('monetization', '#featuresTitle')") {{ $t('landingPage.header.monetizationList[5]') }}
								span(@click.stop="handleClick('monetization', '#commissionTitle')") {{ $t('landingPage.header.monetizationList[6]') }}
								span(@click.stop="handleClick('monetization', '#supportTitle')") {{ $t('landingPage.header.monetizationList[7]') }}
								//- span(v-if="islocaleRuSystemAndIp" @click.stop="handleClick('monetization', '#articlesTitle')") {{ $t('landingPage.header.monetizationList[8]') }}

				.header__nav-item
					span.header__nav-link.is-neurobot.cursor-pointer(@click="handleClickMainMenu('neurobot')") {{ $t('landingPage.header.navList[1]') }}
						.header__nav-wrap
							.header__nav-inner
								span(@click.stop="handleClick('neurobot', '#neuroPreview')") {{ $t('landingPage.header.neurobotList[0]') }}
								span(@click.stop="handleClick('neurobot', '#makeMoneyTitle')") {{ $t('landingPage.header.neurobotList[1]') }}
								span(@click.stop="handleClick('neurobot', '#tasksTitle')") {{ $t('landingPage.header.neurobotList[2]') }}
								span(@click.stop="handleClick('neurobot', '#setupTitle')") {{ $t('landingPage.header.neurobotList[3]') }}
								span(@click.stop="handleClick('neurobot', '#brandTitle')") {{ $t('landingPage.header.neurobotList[4]') }}
								span(@click.stop="handleClick('neurobot', '#sell', -10)") {{ $t('landingPage.header.neurobotList[5]') }}
								span(@click.stop="handleClick('neurobot', '#world')") {{ $t('landingPage.header.neurobotList[6]') }}
				.header__nav-item(v-if="islocaleRuSystemAndIp")
					a.header__nav-link(:href="`https://paywall.pw${$t('contacts.blog')}`" target="_blank") {{ $t('landingPage.header.navList[2]') }}
				.header__nav-item(v-if="islocaleRuSystemAndIp")
					a.header__nav-link(:href="$t('contacts.knowledge_base')" target="_blank") {{ $t('landingPage.header.navList[3]') }}
				.header__nav-item
					a.header__nav-link(href="/subscriber" target="_blank") {{ $t('landingPage.header.navList[4]') }}
			.d-none.d-md-flex
				span.landing__btn.header__btn(@click="$emit('click', $event)") {{ $t('landingPage.header.enter') }}
				//-locale-switch-simple

			.header__menu(:class="{'is-opened' : isOpen}")
				span.header__close(@click="close")

				.header__menu-link.p-0.is-monetization(:class="{'is-opened' : isListMonetization}" @click="toggleListMonetization()")
					span {{ $t('landingPage.header.navList[0]') }}
					.header__menu-wrap(:class="{'is-active' : isListMonetization}")
						span(@click.stop="handleClick('monetization', '#monetizationPreview')") {{ $t('landingPage.header.monetizationList[0]') }}
						span(@click.stop="handleClick('monetization', '#authorsMainProjects')") {{ $t('landingPage.header.monetizationList[1]') }}
						span(@click.stop="handleClick('monetization', '#reviewsTitle')") {{ $t('landingPage.header.monetizationList[2]') }}
						span(v-if="islocaleRuSystemAndIp" @click.stop="handleClick('monetization', '#write', 10)") {{ $t('landingPage.header.monetizationList[3]') }}
						span(@click.stop="handleClick('monetization', '#stepsTitle')") {{ $t('landingPage.header.monetizationList[4]') }}
						span(@click.stop="handleClick('monetization', '#featuresTitle')") {{ $t('landingPage.header.monetizationList[5]') }}
						span(@click.stop="handleClick('monetization', '#commissionTitle')") {{ $t('landingPage.header.monetizationList[6]') }}
						span(@click.stop="handleClick('monetization', '#supportTitle')") {{ $t('landingPage.header.monetizationList[7]') }}
						//- span(v-if="islocaleRuSystemAndIp" @click.stop="handleClick('monetization', '#articlesTitle')") {{ $t('landingPage.header.monetizationList[8]') }}

				.header__menu-link.p-0.is-neurobot(:class="{'is-opened' : isListNeurobot}" @click="toggleListNeurobot()")
					span {{ $t('landingPage.header.navList[1]') }}
					.header__menu-wrap(:class="{'is-active' : isListNeurobot}")
						span(@click.stop="handleClick('neurobot', '#neuroPreview')") {{ $t('landingPage.header.neurobotList[0]') }}
						span(@click.stop="handleClick('neurobot', '#makeMoneyTitle')") {{ $t('landingPage.header.neurobotList[1]') }}
						span(@click.stop="handleClick('neurobot', '#tasksTitle')") {{ $t('landingPage.header.neurobotList[2]') }}
						span(@click.stop="handleClick('neurobot', '#setupTitle')") {{ $t('landingPage.header.neurobotList[3]') }}
						span(@click.stop="handleClick('neurobot', '#brandTitle')") {{ $t('landingPage.header.neurobotList[4]') }}
						span(@click.stop="handleClick('neurobot', '#sell', -10)") {{ $t('landingPage.header.neurobotList[5]') }}
						span(@click.stop="handleClick('neurobot', '#world')") {{ $t('landingPage.header.neurobotList[6]') }}
						
				a.header__menu-link(v-if="islocaleRuSystemAndIp" :href="`https://paywall.pw${$t('contacts.blog')}`" target="_blank" @click="close") {{ $t('landingPage.header.navList[2]') }}
				a.header__menu-link(v-if="islocaleRuSystemAndIp" :href="$t('contacts.knowledge_base')" target="_blank" @click="close") {{ $t('landingPage.header.navList[3]') }}
				a.header__menu-link.no-border(href="/subscriber" target="_blank" @click="close") {{ $t('landingPage.header.navList[4]') }}

				.d-flex
					span.landing__btn.header__menu-button(@click="$emit('click', $event)") {{ $t('landingNew.header.enter') }}
					//-locale-switch-simple

		.empty-header(v-if="isOpen")
</template>

<script>
import { mapActions } from "vuex";
import LocaleSwitchSimple from "@/components/Common/LocaleSwitchSimple";

export default {
	name: 'Header',
	props: ['idPage'],
	data: () => ({
		lastPosition: 0,
		isOpen: false,
		isListMonetization: false,
		isListNeurobot: false
	}),
	components: {
		LocaleSwitchSimple
	},
	mounted()	{
		window.addEventListener('scroll', this.onScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.onScroll);
	},
	created() {
		window.addEventListener('click', e => {
			if (this.isOpen === true) {
				const target = e.target;

				if (!target.closest('.header__menu') && !target.closest('.header__burger')) {
					this.close()
				}
			}
		});
	},
	methods: {
		...mapActions(['setLang']),
		onScroll() {
			let headerMainEl = null;

			if(this.$route.name == 'index') {
				headerMainEl = document.querySelectorAll('.header-main')[this.idPage];
			} else {
				headerMainEl = document.querySelector('.header-main');
			}

			if (this.lastPosition > window.scrollY && window.scrollY > 184) {
				headerMainEl.classList.add('header-sticky');
			} else {
				headerMainEl.classList.remove('header-sticky');
			}

			this.lastPosition = window.scrollY;
		},
		open() {
			this.isOpen = true;
			document.querySelector('.app').classList.add('overflow');
			document.body.classList.add('is-overflow');
		},
		close() {
			this.isOpen = false;
			document.querySelector('.app').classList.remove('overflow');
			document.body.classList.remove('is-overflow');
		},
		handleClickMainMenu(type) {
			const currentPagePath = this.$route.path;

			if(type == 'all') {
				if(currentPagePath != '/') {
					this.$router.push({name: 'index'});
					window.scrollTo({ top: 0, behavior: "instant"});
				} else {
					this.$emit('all');
				}
				
			} else if(type == 'monetization') {
				if(currentPagePath != '/') {
					this.$router.push({name: 'index', params: { type: type }});
				} else {
					this.$emit('monetization');
				}
			} else if(type == 'neurobot' && currentPagePath != '/ai') {
				this.$router.push({name: 'landingNeurobot'});
				window.scrollTo({ top: 0, behavior: "instant"});
			}

			const headerMainElems = document.querySelectorAll('.header-main');

			headerMainElems.forEach((headerMainEl) => {
				headerMainEl.classList.remove('header-sticky');
			});
		},

		handleClick(type, block, distance) {
			const currentPagePath = this.$route.path;

			if(type == 'monetization') {
				if(currentPagePath != '/') {
					this.$router.push({name: 'index', params: { type: type, block: block, distance: distance }});
				} else {
					this.$emit('monetization');
					this.scrollToSection(block, distance);
				}
			} else if(type == 'neurobot') {
				if(currentPagePath != '/ai') {
					this.$router.push({name: 'landingNeurobot', params: { block: block, distance: distance }});
				} else {
					this.scrollToSection(block, distance);
				}
			}
			this.close();
		},
		scrollToSection(block, distance) {
			let distanceBlock = 0;
			const targetBlock = document.querySelector(block);

			if(distance == null) {
				distanceBlock = -30;
			} else {
				distanceBlock = distance;
			}

			setTimeout(() => {
				const top = window.scrollY + targetBlock.getBoundingClientRect().y + distanceBlock;

				window.scrollTo(0, top);
			}, 0);
		},
		toggleListMonetization() {
			this.isListMonetization = !this.isListMonetization;
		},
		toggleListNeurobot() {
			this.isListNeurobot = !this.isListNeurobot;
		}
	}
}
</script>

<style lang="scss" scoped>

.header-main {
	top: -184px;
	margin: 0 auto;
	width: calc(100% - 32px);
	max-width: 1239px;
	padding-top: 36px;

	@media(max-width: 991.98px) {
		padding-top: 23px;
	}

	@media(max-width: 767.98px) {
		width: calc(100% - 25px);
		padding-top: 10px;
	}

	&.header-sticky {
		z-index: 98;
		position: sticky;
		top: 22px;
		left: 0;
		right: 0;
		padding-top: 0;
		transition: top 300ms linear;

		@media(max-width: 991.98px) {
			top: 8px;
		}
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 23px 42px 23px 44px;
	width: 100%;
	border-radius: 100px;
	background-color: var(--lc-transparent-ebony);
	margin-bottom: 32px;
	z-index: 20;
	/* Скрываем scrollbar для IE, Edge и Firefox */
	-ms-overflow-style: none;  /* IE и Edge */
	scrollbar-width: none;  /* Firefox */
	backdrop-filter: blur(40px);
	top: -400px;

	@media (max-width: 1200px) {
		padding-left: 32px;
		padding-right: 32px;
	}

	@media (max-width: 991.98px) {
		padding: 15px 25px;
	}

	@media (max-width: 767.98px) {
		margin-bottom: 26px;
	}

	@media (max-width: 575.98px) {
		margin-bottom: 20px;
	}

	/* Скрываем scrollbar для Chrome, Safari и Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	&.is-opened {
		@media (max-width: 767.98px) {
			display: block !important;
			border-radius: 0;
			width: 100%;
			padding: 28px 37px 17px 37px;
			z-index: 100;
			position: fixed;
			top: 0;
			left: 0;
			transition: top 400ms ease-out;
			backdrop-filter: blur(40px);
			overflow: none;
		}
	}

	&.is-opened-list {
		@media (max-width: 767.98px) {
			height: 100%;
			overflow: auto;
		}
	}

	&__logo {
		width: 97px;
		height: 24px;
		margin-top: -2px;
		mask-image: url('/assets/img/landingPage/header/logo.svg');
		mask-size: cover;
		background-color: var(--lc-white);
		transition: opacity var(--la-time);

		@media (max-width: 1199.98px) {
			margin-top: 0;
		}

		@media (max-width: 991.98px) {
			width: 78px;
			height: 18px;
		}

		&:hover,
		&:active {
			opacity: 0.8;
		}
	}

	&__nav {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;

		@media(max-width: 767.98px) {
			display: none;
		}

		&-item {
			background-color: transparent;
			padding: 18px 19px;
			border-radius: 37px;
			transition: background-color var(--la-time);
			margin-right: 2.5px;
			position: relative;
			display: flex;
			font-weight: 400;

			@media (max-width: 1199.98px) {
				padding: 15px 16px;
			}

			@media (max-width: 991.98px) {
				padding: 9px 10px;
			}

			&:has( > .header__nav-link:hover) {
				background-color: var(--lc-transparent-ebony);
			}

			&:last-of-type {
				margin-right: 0;
			}
		}

		&-link {
			color: var(--lc-white);
			position: relative;

			@media (max-width: 1199.98px) {
				font-size: 13px;
			}

			@media (max-width: 991.98px) {
				font-size: 10px;
			}

			&:last-child {
				margin-right: 0;
			}

			&.is-monetization, &.is-neurobot {
				padding-right: 23px;

				@media (max-width: 991.98px) {
					padding-right: 18px;
				}

				&:after {
					content: '';
					display: block;
					width: 18px;
					height: 18px;
					position: absolute;
					right: -1px;
					top: -1.5px;
					background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9405 6.71094L10.0505 11.6009C9.47305 12.1784 8.52805 12.1784 7.95055 11.6009L3.06055 6.71094' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center / cover;
					transition: transform var(--la-time);

					@media (max-width: 1199.98px) {
						width: 16px;
						height: 16px;
					}

					@media (max-width: 991.98px) {
						width: 12px;
						height: 12px;
					}
				}

				&:hover {
					&:after {
						transform: rotate(180deg);
					}
				}
			}

			&:hover {
				color: var(--lc-white) !important;

				.header__nav-wrap {
					display: block;
				}
			}
		}

		&-wrap {
			display: none;
			position: absolute;
			left: -19px;
			top: 14px;
			padding-top: 30px;
			width: max-content;
			cursor: default;

			@media (max-width: 1199.98px) {
				left: -16px;
				top: 10px;
				padding-top: 25px;
			}

			@media (max-width: 991.98px) {
				left: -10px;
				padding-top: 20px;
			}
		}

		&-inner {
			background-color: var(--lc-ebony);
			border-radius: 15px;
			padding: 24px 21px;

			span {
				display: block;
				margin-bottom: 24px;
				color: var(--lc-white);
				transition: opacity var(--la-time);
				cursor: pointer;

				&:last-of-type {
					margin-bottom: 0;
				}

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}

	&__btn {
		color: var(--lc-black);
		width: 100px;
		height: 42px;
		min-height: 42px;
		margin-left: 2px;
		font-size: 16px;

		@media (max-width: 991.98px) {
			font-size: 13px;
			width: 70px;
			height: 35px;
			min-height: 35px;
		}
	}

	&__burger-wrap {
		display: none;
		align-items: center;

		.header__menu-button {
			margin: 0 20px 0 0;
		}

		@media(max-width: 767.98px) {
			display: flex;
		}
	}

	&__burger {
		display: none;

		@media(max-width: 767.98px) {
			display: block;
			width: 24px;
			height: 24px;
			mask-image: url('/assets/img/landingPage/header/burger.svg');
			mask-size: cover;
			background-color: var(--lc-white);
		}
	}

	&__menu {
		display: none;

		@media(max-width: 767.98px) {
			margin-top: 26px;
		}

		&.is-opened {
			@media (max-width: 767.98px) {
				display: block;
			}
		}

		&-link {
			@media(max-width: 767.98px) {
				display: block;
				padding-bottom: 15.75px;
				border-bottom: 1px solid var(--lc-transparent-4-white);
				color: var(--lc-white);
				margin-top: 15.75px;
				font-weight: 400;

				&.no-border {
					border: none;
				}

				&:hover,
				&:focus {
					color: var(--lc-white);
				}

				&:first-child {
					margin-top: 0;
				}
			}

			&.is-monetization, &.is-neurobot {
				& > span {
					position: relative;

					&:after {
						content: '';
						display: block;
						width: 18px;
						height: 18px;
						position: absolute;
						right: -23.5px;
						top: -1px;
						background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.9405 6.71094L10.0505 11.6009C9.47305 12.1784 8.52805 12.1784 7.95055 11.6009L3.06055 6.71094' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center / cover;
						transition: transform var(--la-time);
					}
				}

				&.is-opened {
					& > span {
					
						&:after {
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		&-wrap {
			padding: 0;
			position: relative;
			left: -500px;
			height: 0;

			&.is-active {
				left: 0;
				height: auto;
				transition: left .4s ease-in-out;
			}

			span {
				display: block;
				margin-top: 16px;
				margin-bottom: 24px;
				padding-left: 24px;
				font-weight: 400;

				&:last-of-type {
					margin-bottom: 16px;
				}
			}
		}

		&-button {
			@media(max-width: 767.98px) {
				width: 100%;
				height: 42px;
				min-height: 42px;
				margin-top: 8.5px;
			}
		}
	}

	&__close {
		@media(max-width: 767.98px) {
			position: absolute;
			top: 30px;
			right: 37px;
			width: 16px;
			height: 16px;
			background-image: url('/assets/img/landingPage/header/close.svg');
			background-size: cover;
		}
	}

	.locale-switch {
		background-color: var(--lc-transparent-3-white);
		border-radius: 50px;
		width: 65px;
		height: 42px;
		margin-left: 20px;
		transition: transform var(--la-time);

		@media (max-width: 1199.98px) {
			width: 47px;
			margin-left: 15px;
		}

		@media (max-width: 991.98px) {
			width: 45px;
			margin-left: 10px;
			height: 35px;
		}

		@media (max-width: 767.98px) {
			width: 65px;
			height: 42px;
			margin-top: 8.5px;
			flex-shrink: 0;
		}

		&:hover {
			transform: scale(1.02);
		}
	}
}

.empty-header {
	height: 130px;

	@media(max-width: 991.98px) {
		height: 103.5px;
	}

	@media (max-width: 767.98px) {
		height: 74px;
	}
}
</style>
